import React from "react"

import Footer from "../components/footer"
import Header from "../components/header"
export default () => {
  return (

  
<div class="min-h-screen bg-white mb-4">
  <Header/>

  <main class="overflow-hidden">

    <div class="bg-gray-50">
      <div class="py-24 lg:py-32">
        <div class="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
          <h1 class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">Landscaping</h1>
          <p class="mt-6 text-xl text-gray-500 max-w-3xl">MV Property Care offers landscaping services in Clarion and the surrounding areas. </p>
          <h2 class="text-xl font-extrabold tracking-tight text-gray-900 mt-6">GENERAL LANDSCAPING SERVICES</h2>
            <ul>
              <li>Planting flowers</li>
              <li>Planting flower beds</li>
              <li>Shrubs</li>
            </ul>
            <h2 class="text-xl font-extrabold tracking-tight text-gray-900 mt-6">DECORATIVE STONE</h2>
            <ul>
              <li>Lava Rock</li>
              <li>Marble Chips</li>
              <li>Carnation Pink</li>
              <li>Goose Egg</li>
              <li>Maryland River Stone</li>
            </ul>
            <h2 class="text-xl font-extrabold tracking-tight text-gray-900 mt-6">MULCH INSTALLATION</h2>
            <ul>
              <li>Hardwood double ground</li>
              <li>Hardwood triple ground</li>
              <li>Dyed Mulch (red, burgundy, brown, black)</li>
            </ul>
        </div>
      </div>
    </div>

    

  </main>

  <Footer/>
</div>
 
    
  )
}
