import React from "react";
import { Link } from "gatsby"
import logoImage from "../images/mv-logo.png"

function Footer() {
  return (
  
   
    <footer class="bg-gray-900 mt-8" aria-labelledby="footerHeading">
    <h2 id="footerHeading" class="sr-only">Footer</h2>
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <div class="space-y-8 xl:col-span-1">
          <img class="h-10" src={logoImage} alt="MV Property Care"/>
          <p class="text-gray-400 text-base">
          MV Property Care provides lawn care and land maintenance services throughout Clarion County and the surrounding areas, including Clarion, Rimersburg, East Brady, New Bethlehem, Sligo, Knox, Parker, Emlenton, and Foxburg.
          </p>
          <div class="flex space-x-6">
            <a href="https://www.facebook.com/MVPropertyCare/" class="text-gray-400 hover:text-gray-300">
              <span class="sr-only">Facebook</span>
              <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
              </svg>
            </a>


            <a href="https://twitter.com/mvpropertycare" class="text-gray-400 hover:text-gray-300">
              <span class="sr-only">Twitter</span>
              <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
            </a>
            <span class="ml-4 text-gray-400">P.O. Box 71 | Rimersburg, PA 16248</span>
            

          </div>
        </div>
        <div class="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              
              <ul class="mt-4 space-y-4">
                <li>
                  <Link to="/lawn-care" activeStyle={{ color: "#35c151" }} class="text-base text-gray-400 hover:text-gray-300">
                    Lawn Care
                  </Link>
                </li>

                <li>
                  <Link to="/landscaping" activeStyle={{ color: "#35c151" }} class="text-base text-gray-400 hover:text-gray-300">
                    Landcaping
                  </Link>
                </li>

                <li>
                  <Link to="/snow-removal" activeStyle={{ color: "#35c151" }} class="text-base text-gray-400 hover:text-gray-300">
                    Snow Removal
                  </Link>
                </li>

                <li>
                  <Link to="/other-services" activeStyle={{ color: "#35c151" }} class="text-base text-gray-400 hover:text-gray-300">
                    Other Services
                  </Link>
                </li>
                <li>
                  <Link to="/lawn-tips" activeStyle={{ color: "#35c151" }} class="text-base text-gray-400 hover:text-gray-300">
                    Lawn Tips
                  </Link>
                </li>
                <li>
                  <Link to="/contact" activeStyle={{ color: "#35c151" }} class="text-base text-gray-400 hover:text-gray-300 font-bold">
                    Contact Us
                  </Link>
                </li>

               
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              
              <ul class="mt-4 space-y-4">
                

                

               
              </ul>
            </div>
          </div>
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              
            </div>
            <div class="mt-12 md:mt-0">
              
            </div>
          </div>
        </div>
      </div>
      <div class="mt-12 border-t border-gray-700 pt-8">
        <p class="text-base text-gray-400 xl:text-center">
          &copy; 2021 MV Property Care, Inc. All rights reserved.
        </p>
        <br/>
        <p class="text-base text-gray-500 xl:text-center">
          Developed in Pennsylvania by <a href="https://carboncreek.agency" target="_new">Carbon Creek</a>.
        </p>
      </div>
    </div>
  </footer>
  
    
    
  );
}

export default Footer;
