import React, { useState } from "react";
import { Link } from "gatsby"
import logoImage from "../images/mv-logo.png"

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <header class="relative">
    <div class="relative pt-6 px-4 sm:px-6 lg:px-8">
          <nav class="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
            <div class="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
              <div class="flex items-center justify-between w-full md:w-auto">
                <Link to="../">
                  <span class="sr-only">MV Property Care</span>
                  <img class="w-20" src={logoImage} alt="MV Logo" />
                </Link>
                <div class="-mr-2 flex items-center md:hidden">
                  <button onClick={() => setIsMenuOpen(true)} type="button" class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-expanded="false">
                    <span class="sr-only">Open main menu</span>
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
              <Link to="/lawn-care" class="font-medium text-gray-500 hover:text-gray-900">Lawn Care</Link>
              <Link to="/landscaping" class="font-medium text-gray-500 hover:text-gray-900">Landcaping</Link>
              <Link to="/snow-removal" class="font-medium text-gray-500 hover:text-gray-900">Snow Removal</Link>
              <Link to="/other-services" class="font-medium text-gray-500 hover:text-gray-900">Other Services</Link>
              <Link to="/lawn-tips" class="font-medium text-gray-500 hover:text-gray-900">Lawn Tips</Link>
              <Link to="/contact" class="font-medium text-mvgreen hover:text-indigo-500">Contact Us</Link>
            </div>
          </nav>
        </div>
  
        {isMenuOpen && (
      <div class="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top lg:hidden">
        <div class="rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="px-5 pt-4 flex items-center justify-between">
            <div>
              <img class="h-8 w-auto" src={logoImage} alt="MV Logo"/>
            </div>
            <div class="-mr-2">
              <button onClick={() => setIsMenuOpen(false)} type="button" class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-mvteal">
                <span class="sr-only">Close menu</span>
               
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
          <div class="pt-5 pb-6">
            <div class="px-2 space-y-1">
              <Link to="/lawn-care" class="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50">Lawn Care</Link>  
              <Link to="/landscaping" class="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50">Landcaping</Link>  
              <Link to="/snow-removal" class="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50">Snow Removal</Link>
              <Link to="/other-services" class="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50">Other Services</Link>
             
              <Link to="/lawn-tips" class="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50">Lawn Tips</Link>
              
            </div>
            <div class="mt-6 px-5">
              <Link to="/contact" class="block text-center w-full py-2 px-4 border border-transparent rounded-md shadow bg-mvteal text-white font-medium hover:bg-teal-600">Contact Us</Link>
            </div>
          </div>
        </div>
      </div>
       )}
    </header>
   
   
    
  );
}

export default Header;
